dmx.Component('data-detail', {

    initialData: {
        data: {}
    },

    attributes: {
        data: {
            type: Array,
            default: []
        },

        key: {
            type: String
        },

        value: {
            type: [String, Number]
        }
    },

    methods: {
        select: function(value) {
            this.props.value = value;
            this.select(value, true);
        }
    },

    render: function(node) {
        this.items = [];
        this.setData(this.props.data);
        this.select(this.props.value);
        this.$parse();
    },

    update: function(props, fields) {
        if (fields.has('data')) {
            this.setData(this.props.data);
            this.select(this.props.value);
        } else if (props.value !== this.props.value) {
            this.select(this.props.value, true);
        }
    },

    setData: function(data) {
        this.items = [];

        if (data && typeof data == 'object') {
            if (Array.isArray(data)) {
                this.items = data.map(function(item) {
                    return (typeof item === 'object') ? item : { $value: item };
                });
            } else {
                for (var key in data) {
                    if (!data.hasOwnProperty(key)) continue;
                    this.items.push({ $key: key, $value: data[key] });
                }
            }
        }
    },

    select: function(value, reset) {
        var index = this.items.findIndex(function(item) {
            return item[this.props.key] === value;
        }, this);

        this.set('data', this.items[index] || null);

        if (reset) {
            var node = this.$node;

            requestAnimationFrame(function() {
                var forms = node.querySelectorAll('form');

                for (var i = 0; i < forms.length; i++) {
                    forms[i].reset();
                }
            });
        }
    }

});
